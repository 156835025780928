/* src/components/AddPost.module.css */
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px auto;
    background: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
}

.inputGroup {
    width: 100%;
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #666;
    font-size: 16px;
}

input[type="text"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Adds padding without increasing the width */
    transition: border-color 0.3s;
}

input[type="text"]:focus,
textarea:focus {
    border-color: #0056b3; /* Highlight focus border */
    outline: none;
}

button {
    padding: 10px 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #003973; /* Darken button on hover */
}
