/* src/components/Posts.module.css */
.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.post {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.15);
}

.title {
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
    font-weight: bold;
}

.content {
    font-size: 16px;
    color: #666666;
}

.noPosts {
    text-align: center;
    color: #888888;
    font-size: 18px;
    font-style: italic;
}
